import { Divider, Flex, notification } from "antd";
import { useState } from "react";
import { styled, useTheme } from "styled-components";
import Row from "../containers/Row";
import { StyledButton, StyledTitle } from "../components/Typography";
import { t } from "i18next";
import { formatPrice } from "../helpers/textTransformation";
import { mockAuction } from "../mocks/auction";
import Stepper from "../containers/Stepper";

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    width: 65%;
  }
`;
const RightPanel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: 0px 4px 8px 0px #00154015;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 24px;
  justify-content: space-between;
  @media (min-width: 768px) {
    width: 25%;
  }
`;
const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ShoppingCart = () => {
  const [notifApi, contextHolder] = notification.useNotification();
  const theme = useTheme();

  return (
    <Row>
      {contextHolder}
      <ProductWrapper>
        <LeftPanel>
          <StyledTitle>{t("shoppingCart.title")}</StyledTitle>
          <Stepper />
        </LeftPanel>
        <RightPanel>
          <InformationWrapper>
            <div>{t("auction.description")}: </div>
            <br />
            <div>
              <b>{mockAuction.description}</b>
            </div>
            <Divider />
            <div>{t("auction.descriptionProvider")}: </div>
            <br />
            <div>
              <b>{mockAuction.providerDescription}</b>
            </div>
            <Divider />
            <div>{t("auction.price")}: </div>
            <div>
              <StyledTitle style={{ color: theme.colors.primary }}>
                {formatPrice(mockAuction.price)}
              </StyledTitle>
            </div>
            <Divider />
          </InformationWrapper>
          <ButtonsWrapper>
            <StyledButton>{t("auction.claimPrice")}</StyledButton>
            <StyledButton>{t("auction.buy")}</StyledButton>
          </ButtonsWrapper>
        </RightPanel>
      </ProductWrapper>
    </Row>
  );
};

export default ShoppingCart;
