import { styled } from "styled-components";
import { StyledButton } from "../components/Typography";
import { useState } from "react";
import { Flex, notification, Steps } from "antd";
import { t } from "i18next";

const StyledSteps = styled(Steps)`
  .ant-steps-icon {
    color: ${({ theme }) => theme.colors.white} !important;
  }
  .ant-steps-item-title {
    font-weight: 700;
  }
  .ant-steps-item-icon {
    background-color: ${({ theme }) => theme.colors.blue500} !important;
    border-color: ${({ theme }) => theme.colors.blue500} !important;
  }
  .ant-steps-item-finish {
    .ant-steps-item-title {
      color: ${({ theme }) => theme.colors.primary} !important;
    }
    .ant-steps-item-icon {
      background-color: ${({ theme }) => theme.colors.primary} !important;
      border-color: ${({ theme }) => theme.colors.primary} !important;
    }
    .ant-steps-item-tail {
      &::after {
        background-color: ${({ theme }) => theme.colors.primary} !important;
      }
    }
  }
`;

const Stepper = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [notifApi, contextHolder] = notification.useNotification();

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const finish = () => {
    notifApi.success({
      message: "Hotovo!!!!",
    });
  };

  const steps = [
    {
      title: t("shoppingCart.step1.title"),
      content: <div>1111</div>,
    },
    {
      title: t("shoppingCart.step2.title"),
      content: <div>2222</div>,
    },
    {
      title: t("shoppingCart.step3.title"),
      content: <div>3333</div>,
    },
    {
      title: t("shoppingCart.step4.title"),
      content: <div>4444</div>,
    },
  ];
  return (
    <>
      <StyledSteps
        current={currentStep}
        items={steps}
        size="small"
        direction="horizontal"
        initial={0}
        labelPlacement="vertical"
      />

      <div>{steps[currentStep].content}</div>
      <Flex dir="col">
        {currentStep > 0 && (
          <StyledButton onClick={prev}>{t("shoppingCart.prev")}</StyledButton>
        )}
        {currentStep < steps.length - 1 && (
          <StyledButton onClick={next}>{t("shoppingCart.next")}</StyledButton>
        )}
        {currentStep === steps.length - 1 && (
          <StyledButton onClick={finish}>
            {t("shoppingCart.finish")}
          </StyledButton>
        )}
      </Flex>
    </>
  );
};

export default Stepper;
