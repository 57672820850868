export const mockAuction = {
  title: "Win an airstream® caravel & ram® 1500",
  description: "Experience the insane thrills of its 4.0L V8 biturbo engine",
  price: 2500000,
  photoUrls: [
    "https://www.autoweb.cz/wp-content/uploads/2019/09/titulka-chiron-super-sport-300-jde-do-produkce.jpg",
    "https://www.autoweb.cz/wp-content/uploads/2019/09/bugatti-chiron-sport-built-for-top-speed-run-1-1100x618.jpg",
  ],
  providerDescription:
    "Prodejce Mercedes prodavá limitovanou edici tohoto vozu",
};
