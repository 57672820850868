import { FC, PropsWithChildren } from "react";
import { styled } from "styled-components";

type RowProps = {
  fullWidth?: boolean;
};

const StyledRow = styled.div<RowProps>`
  display: flex;
  padding: ${(props) => (props.fullWidth ? "0" : "0 20px")};

  @media (min-width: 768px) {
    padding: ${(props) => (props.fullWidth ? "0" : "0 80px")};
  }
  @media (min-width: 1248px) {
    padding: ${(props) => (props.fullWidth ? "0" : "0 160px")};
  }
`;

const Row: FC<PropsWithChildren<RowProps>> = ({ fullWidth, children }) => {
  return <StyledRow fullWidth={fullWidth}>{children}</StyledRow>;
};

export default Row;
