import { Modal } from "antd";
import { Fragment, useCallback, useState } from "react";
import { styled, useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { MenuItems } from "../state/menu";
import { StyledButton } from "./Typography";
import { t } from "i18next";
import { signOut } from "firebase/auth";
import { auth } from "../authorization/firebase";

const StyledLogout = styled(StyledButton)`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const LogoutButton = () => {
  const [modalLogoutActive, setModalLogoutActive] = useState<boolean>(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const toggleModal = useCallback(
    (event: boolean) => () => {
      setModalLogoutActive(event);
    },
    [modalLogoutActive, setModalLogoutActive]
  );

  const handleSignOut = useCallback(() => {
    setModalLogoutActive(false);
    signOut(auth);
    navigate(`/${MenuItems.LogIn}`);
  }, [navigate, toggleModal]);

  return (
    <Fragment>
      <StyledLogout onClick={toggleModal(true)}>
        {t("auth.logout")}
      </StyledLogout>
      <Modal
        open={modalLogoutActive}
        okButtonProps={{ style: { backgroundColor: theme.colors.primary } }}
        onClose={toggleModal(false)}
        onCancel={toggleModal(false)}
        cancelText={t("casual.cancel")}
        okText={<div>{t("auth.logout")}</div>}
        title={t("auth.logoutConfirm")}
        onOk={handleSignOut}
      />
    </Fragment>
  );
};

export default LogoutButton;
