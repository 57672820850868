export const defaultTheme = {
  colors: {
    primary: "#F6A31C",
    secondary: "#FEF6E8",
    blue500: "#171F41",
    background: "#f0f2f5",
    grey: "#606060",
    lightGrey: "#7D7D7D",
    facebook: "#1877F2",
    white: "#fff",
    text: "#000",
    border: "#EDEDED",
  },
  borderRadius: "8px",
  fontSize: "16px",
};
