// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Zkopírujte svou Firebase konfiguraci sem
const firebaseConfig = {
  apiKey: "AIzaSyAJ38CRNE0rtTEWqhAov4cSO6TKinGYODQ",
  authDomain: "trefimto.firebaseapp.com",
  projectId: "trefimto",
  storageBucket: "trefimto.firebasestorage.app",
  messagingSenderId: "570475241659",
  appId: "1:570475241659:web:e837c1f349da72e7be899e",
  measurementId: "G-FJCY63Q89R",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
