import { styled } from "styled-components";
import Row from "../containers/Row";
import { StyledTitle } from "../components/Typography";

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    width: 65%;
  }
`;

const AboutUs = () => {
  return (
    <Row>
      <LeftPanel>
        <StyledTitle>O nás</StyledTitle>
      </LeftPanel>
    </Row>
  );
};

export default AboutUs;
