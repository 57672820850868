import React, { useState } from "react";
import type { MenuProps } from "antd";
import { Drawer, Menu, Image, Button, Modal } from "antd";
import { styled, useTheme } from "styled-components";
import { MenuOutlined } from "@ant-design/icons";
import Logo from "../assets/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { MenuItems, selectedMenu } from "../state/menu";
import { useRecoilState } from "recoil";
import ShoppingCartButton from "../components/ShoppingCart/ShoppingCartButton";
import LogoutButton from "../components/LogoutButton";
import { t } from "i18next";

type MenuItem = Required<MenuProps>["items"][number];

const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 89px;
  min-height: 89px;
  font-weight: 600;
  padding: 0 20px;
  @media (min-width: 768px) {
    padding: 0 80px;
  }
  @media (min-width: 1248px) {
    padding: 0 160px;
  }
`;
const StyledMenu = styled(Menu)`
  color: #000;
  border-bottom: none;
  flex: 1;
  display: none;

  @media (min-width: 768px) {
    display: flex;
    margin-left: 80px;
  }

  .ant-menu-item {
    font-size: 16px;
    padding: 0 20px;

    &:hover {
      background-color: #555;
      color: #000;
    }
    &:after {
      border: none !important;
    }
  }
  .ant-menu-item-selected {
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`;

const BurgerButton = styled(Button)`
  display: flex;
  font-size: 20px;
  color: #333;
  border: none;
  background: transparent;

  @media (min-width: 768px) {
    display: none;
  }
`;

const menuItems: MenuItem[] = [
  {
    label: "Všechny aukce",
    key: MenuItems.Auctions,
  },
  {
    label: "Výherci",
    key: MenuItems.Winners,
  },
  {
    label: "O nás",
    key: MenuItems.AboutUs,
  },
  {
    label: "",
    key: MenuItems.ShoppingCart,
    extra: <ShoppingCartButton />,
    style: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",
    },
  },
  {
    label: "",
    key: MenuItems.LogOut,
    extra: <LogoutButton />,
  },
];

const MenuBar: React.FC = () => {
  const [activeMenu, setActiveMenu] = useRecoilState(selectedMenu);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  // Toggle drawer visibility
  const showDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const onClick: MenuProps["onClick"] = (e) => {
    const menuItemKey = e.key as MenuItems;
    if (menuItemKey !== MenuItems.LogOut) {
      setActiveMenu(menuItemKey);
      navigate(`/${menuItemKey}`);
      setOpen(false);
    }
  };

  return (
    <Navbar>
      {/* Burger icon */}
      <BurgerButton
        style={{
          boxShadow: `0 1px 2px ${theme.colors.primary}`,
          marginRight: 20,
        }}
        onClick={showDrawer}
      >
        <MenuOutlined
          style={{
            color: theme.colors.primary,
          }}
        />
      </BurgerButton>
      <Link to="/" onClick={() => setActiveMenu(MenuItems.Home)}>
        <Image src={Logo} preview={false} height={34} />
      </Link>
      {/* Drawer Menu for Mobile */}
      <Drawer title="Menu" placement="right" onClose={closeDrawer} open={open}>
        <Menu mode="vertical">
          {menuItems.map((menuItem: any) => {
            if (menuItem.key === MenuItems.ShoppingCart) {
              return (
                <Menu.Item onClick={onClick} key={menuItem.key}>
                  {t("shoppingCart.title")}
                </Menu.Item>
              );
            } else if (menuItem.key === MenuItems.LogOut) {
              return <LogoutButton />;
            } else {
              return (
                <Menu.Item onClick={onClick} key={menuItem.key}>
                  {menuItem.label}
                </Menu.Item>
              );
            }
          })}
        </Menu>
      </Drawer>
      {/* Desktop Menu */}
      <StyledMenu
        onClick={onClick}
        selectedKeys={[activeMenu]}
        mode="horizontal"
        items={menuItems}
      />
      <Modal open={false}>Logout</Modal>
    </Navbar>
  );
};

export default MenuBar;
