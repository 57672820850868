import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { MenuItems, selectedMenu } from "../../state/menu";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";

type BasketProps = {
  active: string | undefined;
};

const StyledBasket = styled(ShoppingCartOutlined)<BasketProps>`
  display: flex;
  align-items: center;
  margin-left: auto;
  svg {
    font-size: 24px;
    color: ${({ theme, active }) =>
      active === "true" ? theme.colors.primary : theme.colors.text};
  }
`;

const ShoppingCartButton = () => {
  const [activeMenu, setActiveMenu] = useRecoilState(selectedMenu);
  const navigate = useNavigate();
  const isShoppingCartMenuActive =
    activeMenu == MenuItems.ShoppingCart ? true : false;

  const onCartClick = useCallback(() => {
    setActiveMenu(MenuItems.ShoppingCart);
    navigate(`/${MenuItems.ShoppingCart}`);
  }, []);

  return (
    <StyledBasket
      onClick={onCartClick}
      active={isShoppingCartMenuActive ? "true" : undefined}
    />
  );
};

export default ShoppingCartButton;
